import {types} from "../types/types";

const initialState = {

    events: [{

        end: "",
        id: "999999999",
        start: "2021-04-24T09:00:00.000Z",
        type: "HALTEROFILIA +  POWER  + WOD ",
        userclase: 7,
        users: Array()
    }]
}

export const eventsReducer = ( state = initialState, action) => {

    switch ( action.type) {
        case types.userGetEvents:
            return {
                ...state,
                events: [...action.payload]
             }
        default:
            return state

    }


}