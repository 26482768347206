import React, {useEffect} from 'react';
import {useSelector} from 'react-redux'
import { CalendarModal } from './CalendarModal';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useDispatch} from "react-redux";
import {eventStartLoading} from "../../actions/events";
import {AddNewEventButton} from "../ui/AddNewEvent";
import {DeleteEventButton} from "../ui/DeleteEventButton";
import {loading} from "../../actions/loading";
import {LoadingApp} from "../LoadingApp";
import getClassTypeColorAdmin from "../../helpers/getClassTypeColorAdmin";


moment.locale('ES');


export const CalendarScreen = () => {

    const { events, activeEvent } = useSelector(state => state.calendar);
    const active = useSelector( state => state.loading.active)

    const dispatch = useDispatch();

    setTimeout(function () {
        dispatch(loading(false))
    }, 1000)


    useEffect( () => {
        dispatch( eventStartLoading() )
    },[ dispatch])

    return (

        ( active ) ? <LoadingApp /> :

        <div className="calendar py-32 mb-12 px-8">
            <div className="h-auto w-full mb-24 block">
                <AddNewEventButton />
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-6 sm:gap-y-6 setting pb-24">
            {
                events.map( clase => (
                    getClassTypeColorAdmin(clase)
                ))
            }
            </div>
            <CalendarModal />
        </div>
    )
}
