import { types } from '../types/types';
import {fetchWithToken} from "../helpers/fetch";
import Swal from "sweetalert2";
import {savedHistoryBono} from "./history";
import moment from "moment";


export const userActionUpdate = ( user ) => ({

    type: types.updateUser,
    payload: user

})

export const updateUser = ( user ) => {

    return async( dispatch ) => {

        try{

            const resp = await fetchWithToken( `users/update-user/${user._id}`, user, 'PUT')
            const body = await resp.json();

            if( !body.ok ) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Usuarios',
                    text: body.msg,
                })
            }

            if( body.ok ) {
                Swal.fire({
                    icon: 'success',
                    title: 'Usuarios',
                    text: body.msg,
                })

                if( user.bono_id ) {
                    const bono = {
                        bono: user.bono_id,
                        user: user._id,
                        start: moment(user.bono_init).locale("ES"),
                        end: null
                    }

                    dispatch( savedHistoryBono(bono))
                }

                dispatch( getUserBono(user._id))

            }



        } catch ( error ) {

            console.log( error )
        }

    }

}

export const getAllUsers = () => {

    return async( dispatch ) => {

        try{
            const resp = await fetchWithToken('users/get-users-bono');
			const body = await resp.json();
            dispatch( getUsers( body.users ) )

        } catch (error) {

            console.log(error)
        }

    }
}

export const getUserBono = (uid) => {

    return async( dispatch ) => {

        try{

            const resp = await fetchWithToken(`users/get-user-bono/${uid}`)
            const body = await resp.json()

            dispatch( updateActiveUser( body.user ) )

        } catch (error) {

            console.log(error)
        }

    }
}

const updateActiveUser = (user) => ({

        type: types.userUpdateActive,
        payload: user
})

export const getEventsUser  = (uid) => {

    return async(dispatch) => {

        try {

            const resp = await fetchWithToken(`users/clases/${uid}`)
            const body = await resp.json()
            dispatch(savedEventsRedux(body.events))
        } catch ( error ) {
            console.log( error )
        }

    }
}

const savedEventsRedux = ( events ) => ({
    type: types.userGetEvents,
    payload: events
})

export const addBonoToUser = ( user ) => {

    return async( dispatch ) => {

        try {

            const resp = await fetchWithToken(`users/add-bono-user/${user.userId}/${user.bonoIdHistory}`, user, 'PUT');
            const body = await resp.json();

            if( !body.ok ) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Bono',
                    text: body.msg,
                })
            }

            Swal.fire({
                icon: 'success',
                title: 'Bono',
                text: body.msg,
            })

            dispatch( userActiveState( body.userActive ))
            dispatch( getAllUsers())

        } catch ( error ) {
            console.log( error )
        }

    }
}

export const setUserActive = ( user ) => {

    return async ( dispatch ) => {
        dispatch(  userActiveState( user ))
    }
}

const userActiveState = ( user ) => ({
    type: types.userActive,
    payload: user
})

const getUsers = (users) => ({

    type: types.usersGetAll,
    payload: users

})

export const deleteBonoUserModal = ( user, bono ) => {

    return async( dispatch ) => {

        try {

            const resp = await fetchWithToken(`users/delete-bono-user/${user._id}/${bono._id}`, user, 'PUT');
            const body = await resp.json();

            if( !body.ok ) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Bono',
                    text: body.msg,
                })
            }

            dispatch( deleteBonoUserModalState( body.user ))
            dispatch( getAllUsers())

			return Swal.fire({
                icon: 'success',
                title: 'Bono',
                text: body.msg,
            })

        } catch ( error ) {
            console.log( error )
        }

    }

}

const deleteBonoUserModalState = ( user ) => ({
    type: types.userDeleteBono,
    payload: user
})


export const getActionsCountClassesPendingMonthAdmin = ( id ) => {

	return async (dispatch) => {

		try {

			await fetchWithToken(`bonos/count-classes/${id}`, {id}, 'PUT');

			dispatch( getAllUsers())

		} catch (error) {

			console.log( error )
		}
	}
}

export const addDateClassDay = ( ) => ({
    type: types.addDateClassDayUser,
})

export const deleteDateClassDayActions = ( ) => ({
    type: types.deleteDateClass,
})

export const getActionsCountClassesPendingMonthAdminAll = () => {

	return async (dispatch) => {

		try {

			await fetchWithToken(`bonos/count-classes`);

			dispatch( getAllUsers())

		} catch (error) {

			console.log( error )
		}


	}
}
