import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import DateTimePicker from "react-datetime-picker";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {uiCloseModal} from "../../actions/ui";
import {eventSetActive, eventStartLoading, eventStartUpdated, savedNewEvent} from "../../actions/events";
import {DeleteEventButton} from "../ui/DeleteEventButton";
moment.locale('es');
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        width                 : '100%'
    }
};

Modal.setAppElement('#root');

const now = moment().minutes(0).seconds(0).add(1, 'hours');

const nowPlusHour = now.clone().add(1, 'hours')


export const CalendarModal = () => {

    const dispatch = useDispatch();
    const [ startDate, setStartDate ] = useState( now.toDate() );
    const [ endDate, setEndDate ] = useState( nowPlusHour.toDate() );
    const [ error, setError ] = useState( false );
    const [ messageError, setMessageError ] = useState('');

    const {modalOpen} = useSelector( state => state.ui);
    const { activeEvent } = useSelector( state => state.calendar);

    const initialState = {

        event: {
            type: 'Funcional',
            userClase: 0,
            users: [],
            start: '',
            end: ''
        },

        activeEvent: null

    }
    const [ formValues, setFormValues ] = useState(initialState);

    const { event } = formValues;

    useEffect(() => {

        if( activeEvent) {
            setFormValues(activeEvent)
        } else {
            setFormValues( initialState )
        }

    }, [activeEvent, dispatch]);


    const handleInputChange = ({ target }) => {

        setFormValues({
            ...formValues,
            [target.name ]: target.value
        })

    }

    const closeModal = () => {

        dispatch( eventSetActive(null))
        dispatch( uiCloseModal() )


    }

    const handleStartDateChange = (e) => {
        setStartDate( e )
        setFormValues({
            ...formValues,
            start: e
        })
    }

    const handleFinishDateChange = (e) => {
        setEndDate( e )
        setFormValues({
            ...formValues,
            end: e
        })
    }

    const handleFormData = (e) => {
        e.preventDefault()

        const momentStart = moment(startDate);
        const momentEnd = moment(endDate);

        if(momentStart.isSameOrAfter(momentEnd)){
            setMessageError('La fecha de fin no puede ser menor a la de inicio')
            setError(true)

            return false;
        }

        if(activeEvent) {
            dispatch( eventStartUpdated(formValues))

        } else {
            if(event.type.trim() === '') {
                setMessageError('Los campos no pueden estar vacíos')
                setError(true)
                return false;
            }
            dispatch( savedNewEvent(formValues));
            dispatch(eventStartLoading())
            setFormValues(initialState)
        }

        dispatch(eventStartLoading())
        setError(false);

    }

    return (

        <Modal
            isOpen={ modalOpen }
            onRequestClose={closeModal}
            style={customStyles}
            closeTimeMS={ 500 }
            className="modal"
            overlayClassName="modal-fondo"
        >
            <h1 className="text-2xl uppercase text-center bg-blue-500 text-white mb-3 py-3 font-bold"> {
                (activeEvent === null)
                    ? "Nueva Clase"
                    : `Edita ${activeEvent?.type} `} </h1>
            <form onSubmit={ handleFormData }>
                <div className="px-5 py-5">

                    {(error) ? <p className="bg-red-500 text-white py-2 mb-5 text-center rounded-sm">{messageError}</p> : ''}

                    <div className="flex flex-col mb-4">
                            <label className="font-bold text-gray-800 mb-1 block">Tipo de Clase:</label>
                        <select name="type" id="" onChange={handleInputChange} value={activeEvent?.type}
                                className="rounded-sm">
                            <option value="">Dame una opción</option>
                            <option value="CrossTraining">Cross Training</option>
                            <option value="Endurance">Endurance</option>
                            <option value="Open">Open</option>
                            <option value="Halterofilia">Halterofilia</option>
                            <option value="Yoga">Yoga</option>
                            <option value="Gymnastics">Gymnastics</option>
                            <option value="PowerLifting">Powerlifting</option>
                        </select>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label className="font-bold text-gray-800 mb-1 block">Usuarios:</label>
                        <input
                            type="number"
                            className="border-2 border-grey-100 rounded h-10 px-3 focus:ring-1 focus:border-blue-300 focus:border-transparent focus:outline-none"
                            placeholder="Usuarios en clase"
                            name="userClase"
                            value={activeEvent?.userClase}
                            onChange={ handleInputChange }
                            autoComplete="off"
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <label className="font-bold text-gray-800 mb-1">Inicio clase</label>
                        <DateTimePicker
                            onChange={handleStartDateChange}
                            name="startDate"
                            value={ (activeEvent) ? activeEvent?.start : startDate }
                            className="border-2 border-grey-100 rounded h-10 px-3 focus:ring-1 focus:border-blue-300 focus:border-transparent focus:outline-none"
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="font-bold text-gray-800 mb-1">Fin</label>
                        <DateTimePicker
                            onChange={handleFinishDateChange}
                            name="endDate"
                            value={ (activeEvent) ? activeEvent?.end : endDate }
                            minDate={ startDate }
                            className="border-2 border-grey-100 rounded h-10 px-3 focus:ring-1 focus:border-blue-300 focus:border-transparent focus:outline-none"
                        />
                    </div>

                </div>
                
                <div className="acciones grid gap-5 grid-cols-2">
                    <DeleteEventButton />
                    <button
                        type="submit"
                        className="mt-6 bg-green-500 text-white text-center focus:outline-none uppercase font-bold text-xl sm:text-base hover:bg-green-600 rounded py-2 w-full transition duration-500 ease-in-out">
                    
                    <span>
                         Guardar
                    </span>

                    </button>

                </div>

            </form>
        </Modal>
    )
}
