import moment from "moment";

export const ResumenClases = ( event ) => {

    return (

        <div className="class items-center bg-blue-700 pt-4 px-0">
            <h3 className="text-md text-center mb-3">{ event.type.toUpperCase() }</h3>
            <h3 className="text-4xl text-center mb-5 block">🏋🏻‍♂️</h3>
            <h3 className="text-md text-center bg-yellow-500 block p-2 text-blue-900 font-bold">
                { moment(event.start).format('dddd').toUpperCase() } { moment(event.start).format('D') } DE { moment(event.start).format('MMMM').toUpperCase() }</h3>
        </div>

    )
}