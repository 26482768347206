import React from 'react';


const Footer = () => {

    return (
        <>
        <footer className="grid fixed items-center justify-center w-full pt-2 pb-2 px-3 bg-blue-700 bottom-0">
            <div className="link-privacy w-full">
                <a href='https://www.gruposmz.es/eipd/empresa/entrada/sello.aspx?idE=OTA1ODA=&idC=MjM4MjU='>
                    <img className="w-10" src ='https://www.gruposmz.es/eipd/empresa/images/Sello-Vigila.png' alt=''/></a>
            </div>

        </footer>
            </>
    )
}

export default Footer;
