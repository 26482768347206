import React from "react";
import {EventAdmin} from "../components/admin/EventAdmin";

const getClassTypeColorAdmin = ( clase ) => {

    switch (clase?.type){
        case 'Halterofilia':
            return <EventAdmin key={clase?.id} color={{bg: 'bg-yellow-700', icon: '🏋🏻‍♂️'}} clase={clase} />
        case 'Yoga':
            return <EventAdmin key={clase?.id} color={{bg: 'bg-blue-400', icon: '🧘🏻‍♀️'}} clase={clase} />
        case 'Open':
            return <EventAdmin key={clase?.id} color={{bg: 'bg-green-500', icon: '💪'}} clase={clase} />
        case 'Gymnastics':
            return <EventAdmin key={clase?.id} color={{bg: 'bg-blue-300', icon: '🧗🏿‍♂️'}} clase={clase} />
        case 'Funcional':
            return <EventAdmin key={clase?.id} color={{bg: 'bg-pink-700', icon: '🤸‍♀️'}} clase={clase} />
        case 'PowerLifting':
            return <EventAdmin key={clase?.id} color={{bg: 'bg-green-700', icon: '☀️'}} clase={clase} />
        default:
            return <EventAdmin key={clase?.id} color={{bg: 'bg-blue-700', icon: '🤸‍♀️'}} clase={clase} />
    }
}

export default getClassTypeColorAdmin
