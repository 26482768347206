import React from 'react';



const Setting = () => {

    return (
        <div className="flex justify-center content-center pt-20 setting">
            <h1>Setting</h1>
        </div>
    )
}

export default Setting;