import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment'
import {
    eventSetActive,
    eventStartLoading,
} from '../../actions/events';
import {getInitialNameUser} from "../../helpers/getInitialNameUser";
import {uiOpenModal} from "../../actions/ui";
import {CalendarModal} from "./CalendarModal";

export const EventAdmin = (event) => {

    const { color, clase } = event

    const { bg, icon } = color
    const dispatch = useDispatch();
    const { totales } = useSelector( state => state.clases)

    useEffect(() => {
        dispatch( eventStartLoading() )
    }, [ totales, dispatch ])

    const onClickActiveEvent = () => {

        dispatch( eventSetActive(clase))
        dispatch(uiOpenModal())
    }

    return (
        <>
            <div className={`clase rounded h-80 relative bg-blue-700 modal`} onClick={ onClickActiveEvent }>
                <div className="clase__usuarios block h-8 shadow-2xl">
                    <h3 className="text-center px-6 text-red-500 font-bold" title="Máximos usuarios por clase">
                            <span title="Numero máximo de alumnos por clase" className={`absolute top-0 left-0 clase__usuarios-maximos px-5 bg-blue-900 text-4xl`}>{clase?.userClase}<span className="">🏃‍♂️</span>
                                <span className="text-sm block -mt-4">max</span>
                            </span>
                    </h3>
                    <h3 className={`text-center rounded-full px-6 text-green-500 font-bold`} title="Usuarios para esta clase">
                            <span title="Usuarios " className={`absolute top-0 right-0 clase__usuarios-count bg-blue-900 px-5 text-4xl`}>
                                {clase?.users.length > 0 ? clase?.users.length : 0}🏃🏻‍♀️
                                <span className="text-sm block -mt-4">cls</span>
                            </span>
                    </h3>
                </div>

                <div className={`mx-auto w-full text-blue-100 pt-10`}>
                    <span className="text-6xl text-center grid">{icon}</span>
                    <h1 className={`title uppercase text-center text-3xl font-bold tracking-wide ${bg}`}>{clase?.type}
                        <p className="title uppercase text-center text-sm mb-3 font-bold -mt-2">{ moment(clase?.start).format('LL')}</p>
                        <p className="bg-blue-700 py-2 hours uppercase text-3xl font-bold text-white -mt-3">
                            { moment(clase?.start).format('LT') } ⏱ { moment(clase?.end).format('LT') }
                        </p>
                    </h1>


                </div>


                {
                    clase?.users.length > 0 ?
                        <>
                            <div className="clase__usuarios-registrados bg-blue-700 shadow-2xl grid grid-cols-6 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6">

                                {   clase?.users.map((user) =>  (
                                    <div title={user.name} className="text-center flex flex-col justify-center gap-y-2 items-center w-full text-xs"  key={user._id}>
                                        <p className="object-cover flex items-center justify-center rounded-full px-1 cursor-pointer text-1xl bg-yellow-600 text-blue-900 font-bold"> { getInitialNameUser(user.name)} </p>
                                    </div>
                                ))
                                }

                            </div>
                        </>
                        :
                        <div className="flex items-center clase__usuarios-registrados text-blue-100 py-3 px-3">
                            <span className="flex items-center h-10 font-bold">Sin usuarios registrados</span>
                        </div>
                }
            </div>

        </>

    )

}
