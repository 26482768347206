import React from 'react'
import {useSelector} from "react-redux";
import moment from "moment";




export const BonoScreenUserView = (bono) => {

    const bonosState = useSelector( state => state.bonos.bonos)

    const bonos = bonosState.find( bonoState => bonoState._id === bono.bono);
    return (
        <>
            <span className={
                !moment(bono.start).isSameOrAfter( moment().add(1, 'month').startOf('month')) ?
                    "bookmark uppercase bg-green-500 mb-1 text-xs px-1 grid items-center text-center justify-items-center text-white"
                    : "bookmark uppercase bg-red-500 mb-1 text-xs px-1 grid items-center text-center justify-items-center text-white"}>
               {bonos?.name}
                <span key={bono._id} className={"-mt-2 text-xs"}>{moment(bono.start).format("DD-MM-YYYY")}
                    <hr/>
                    {!moment(bono.start).isSameOrAfter(moment().add(1, 'month').startOf('month')) ?
                        ""
                        : <span className={"block text-xs"}>siguiente</span>}

                </span>

            </span>
        </>
    )

}
