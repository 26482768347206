import React from 'react'
import {BonoScreenUserView} from "./BonoScreenUserView";
import {useDispatch, useSelector} from "react-redux";
import {setUserActive} from "../../actions/user";
import {uiOpenModal} from "../../actions/ui";
import {totalBonosPagar} from "../../helpers/totalBonosPagar";
import {LoadingApp} from "../../components/LoadingApp";


export const UserScreen = ( user ) => {

    const dispatch = useDispatch();
    const active = useSelector(state => state.loading.active);
	let { name, email, telefono, bonos, bonoState } = user;
    const bonosState = useSelector( state => state.bonos.bonos)

	let total = totalBonosPagar(bonosState, bonos)


	let bonoStateColors = 'bg-green-500 grid items-center text-center justify-items-center rounded-full h-8 w-8 relative'
	let bonoStateColorsDanger = 'bg-red-500 grid items-center text-center rounded-full h-8 w-8 justify-items-center'

    const handleSelectUser = () => {

        dispatch( setUserActive( user ))
        dispatch( uiOpenModal())
	}

    return (
        ( active ) ? <LoadingApp /> :
        <>
            <tr onClick={handleSelectUser} className="text-white border-blue-50 border-b-2 hover:bg-blue-600 hover:text-white transition-all ease-in-out duration-200 px-2 cursor-pointer">
                <td className="py-2 px-8 border border-white-600">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinejoin="round" strokeWidth="2"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                    </svg>
                </td>
                <td className="py-2 px-8 border border-white-600">{ name }</td>
                <td className="py-2 px-8 border border-white-600">{ email }</td>
                <td className="py-2 px-8 border border-white-600">{ telefono }</td>
                <td className="py-2 px-8 border border-white-600">
                    {
                        ( bonos.length > 0) ? bonos.map(bono => <BonoScreenUserView
                            key={bono._id} {...user} {...bono} />) : <span className="text-md">Sin bonos activos</span>
                    }
                </td>

				<td className="grid items-center justify-items-center py-5 border-white-600">
                    {
                        ((bonoState !== 0)) ? <span className={(bonoState < 2) ? bonoStateColorsDanger : bonoStateColors}>{bonoState}</span>
                        : <span> {bonoState} </span>
                    }
                </td>
				<td className="py-2 px-8 border border-white-600">
                        {
                            total
                        }
				</td>
            </tr>
        </>
    )


}
