import React from 'react'



const Privacy = () => {

    return (
        <div className="pt-24 container grid items-center mx-auto text-blue-200">
            <h1 className="text-center">Privacidad</h1>
        </div>

    )
}


export default Privacy