import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getEventsUser} from "../../actions/user";
import {ResumenClases} from "./ResumenClases";
import {loading} from "../../actions/loading";
import {LoadingApp} from "../../components/LoadingApp";

const UserClases = () => {

    const dispatch = useDispatch()
    const uid  = useSelector( state => state.auth.uid)
    const events = useSelector( state => state.userEvents.events)
    const active = useSelector(state => state.loading.active);
    useEffect( () => {
        dispatch( getEventsUser(uid))
    }, [dispatch])

    setTimeout(() => {
        dispatch(loading(false))
    }, 500)

    return (
        active ? <LoadingApp /> :
        <>

            <div className="container mx-auto pt-48 px-3">
                {events?.length > 0 ?
                <div className="content-clases pb-40">
                    <div className="content-class-title text-center">
                        <div className="content-class_number text-center flex items-center justify-center mb-2">
                            <div className="flex items-center justify-center text-blue-900 font-bold text-4xl rounded-full h-16 w-16 bg-yellow-500">
                                {events?.length}</div>
                        </div>
                        <div className="text-blue-200 font-bold text-2xl px-1 uppercase">
                            Clases mensuales
                        </div>
                    </div>

                        <div className="grid md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10 sm:px-2 md:px-0">
                            {
                                events.map(event => <ResumenClases key={event.id} {...event} />)
                            }
                        </div>

                </div>
                :
                    <div className="flex items-center justify-center px-2 pt-20">
                        <p className="text-yellow-600 uppercase ">Sin eventos por el momento para mostrar</p>
                    </div>
                }
            </div>
        </>
    )
}

export default UserClases