import React from 'react';



const Error404 = () => {

    return (
        <div className="flex justify-center content-center pt-20 setting">
            <h1>Error 404</h1>
        </div>
    )
}

export default Error404;