import React from 'react';


export const SuperAdminHome = () => {

    return (
        <div className="flex items-center h-screen justify-center">
            <h1>Superadmin Home</h1>
        </div>
    )
}