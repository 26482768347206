import React from 'react';
import {useDispatch} from "react-redux";
import {eventStartDeleted} from "../../actions/events";
import {uiCloseModal} from "../../actions/ui";




export const DeleteEventButton = () => {

    const dispatch = useDispatch();

    const HandleDeleteEvent = () => {

        dispatch( eventStartDeleted() )
        dispatch(uiCloseModal())

    }

    return (

            <button
                onClick={HandleDeleteEvent}
                className="mt-6 py-3 bg-red-500 text-white text-center focus:outline-none uppercase font-bold text-xl sm:text-base hover:bg-red-600 rounded transition duration-500 ease-in-out">
                <span>Eliminar</span>
            </button>

    )

}
