import {types} from "../types/types"


const INITIAL_STATE = {

    bonoMesActual: "2021-08-12",
    bonoMesSiguiente: "2021-09-01"
}



const bonoMesSiguienteReducer = ( state = INITIAL_STATE, action) => {

    switch (action.type) {

        case types.bonoMesSiguiente:
            return {
                ...action.payload
            }

        default:
            return state
    }

}

export default bonoMesSiguienteReducer